import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfigurations(ctx, data) {
      return useJwt.getConfigurations(data).then(response => response);
    },
    createConfiguration(ctx, data) {
      return useJwt.createConfiguration(data).then(response => response);
    },
    updateConfiguration(ctx, data) {
      return useJwt.updateConfiguration(data).then(response => response);
    },
    deleteConfiguration(ctx, id) {
      return useJwt.deleteConfiguration(id).then(response => response);
    },
  },
};
